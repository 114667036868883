import { faCog, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink } from "react-router-dom";
import { Bag, BoxArrowUpRight, CashCoin, EnvelopeOpen, House, Kanban, Mortarboard, PlusCircle, Puzzle } from "react-bootstrap-icons";
import useAnalysis from "../hooks/useAnalysis";
import { useTranslation } from "react-i18next";

function Sidebar({ sidebarOpen, setSidebarOpen }: any) {
  const { createAnalysis } = useAnalysis(null);
  const { t } = useTranslation("Component.Sidebar");

  const toggleSidebar = () => {
    setSidebarOpen((prevState: boolean) => !prevState);
  };

  return (
    <aside
      className={`js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered navbar-vertical-aside-initialized ${
        sidebarOpen ? "" : "d-none"
      }`}
    >
      <div className="navbar-vertical-container">
        <div className="navbar-vertical-footer-offset">
          <div className="navbar-brand-wrapper justify-content-between pt-2" style={{ marginLeft: "-0.3rem" }}>
            {/* Logo */}
            <Link className="navbar-brand" to="/" aria-label="Mailerfind">
              <img className="navbar-brand-logo" src="/assets/svg/logos/mailerfind.svg" alt="Logo" style={{ maxWidth: "8rem" }} />
              <img className="navbar-brand-logo-mini" src="/assets/img/logos/mailerfind.png" alt="Logo" />
            </Link>
            {/* End Logo */}
            {/* Navbar Vertical Toggle */}
            <button
              type="button"
              className="js-navbar-vertical-aside-toggle-invoker navbar-vertical-aside-toggle btn btn-icon btn-xs btn-ghost-dark"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            {/* End Navbar Vertical Toggle */}
          </div>
          {/* Content */}
          <div className="navbar-vertical-content mt-3">
            <ul className="navbar-nav navbar-nav-lg nav-tabs">
              <li className="navbar-vertical-aside-has-menu" id="nav-dashboard">
                <NavLink className="js-navbar-vertical-aside-menu-link nav-link" to="/" title={t("dashboard")}>
                  <span className="mr-3">
                    <House />
                  </span>
                  <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">{t("dashboard")}</span>
                </NavLink>
              </li>
              {/* End Dashboards */}
              <li className="nav-item mt-2">
                <small className="nav-subtitle pb-1" title={t("analysis")} style={{ fontSize: ".65625rem" }}>
                  {t("analysis")}
                </small>

                <hr className="nav-subtitle-replacer" />
              </li>
              {/* Apps */}
              <li className="navbar-vertical-aside-has-menu" id="nav-overview">
                <NavLink className="js-navbar-vertical-aside-menu-link nav-link" to="/analysis-manager" title={t("overview")}>
                  <span className="mr-3">
                    <Kanban />
                  </span>
                  <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">{t("overview")}</span>
                </NavLink>
              </li>

              <li className="navbar-vertical-aside-has-menu" id="nav-new-analysis">
                <div
                  className="js-navbar-vertical-aside-menu-link nav-link"
                  title={t("newAnalysis")}
                  style={{ cursor: "pointer" }}
                  onClick={createAnalysis}
                >
                  <span className="mr-3">
                    <PlusCircle />
                  </span>
                  <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">{t("new")}</span>
                </div>
              </li>
              {/* End Apps
                     <li className="nav-item">
                        <small className="nav-subtitle" title="Pages">
                           Data
                        </small>
                        <hr className="nav-subtitle-replacer" />
                     </li>
                     <li className="navbar-vertical-aside-has-menu">
                        <NavLink
                           className="js-navbar-vertical-aside-menu-link nav-link"
                           to="/user-explorer"
                           title="Search Explorer"
                        >
                           <span className="mr-2">
                              <SearchHeart />
                           </span>
                           <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                              User Explorer
                           </span>
                        </NavLink>
                     </li>
                     {/* End Content */}
              <li className="nav-item mt-2">
                <small className="nav-subtitle pb-1" title={t("emailSender")} style={{ fontSize: ".65625rem" }}>
                  {t("emailSender")}
                </small>
                <hr className="nav-subtitle-replacer" />
              </li>
              <li className="navbar-vertical-aside-has-menu" id="nav-emails">
                <NavLink className="js-navbar-vertical-aside-menu-link nav-link" to="/emails" title={t("emails")}>
                  <span className="mr-3 mb-1">
                    <EnvelopeOpen />
                  </span>
                  <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">{t("emails")}</span>
                </NavLink>
              </li>
              <li className="navbar-vertical-aside-has-menu" id="nav-new-email">
                <NavLink className="js-navbar-vertical-aside-menu-link nav-link" to="/emails/new" title={t("newEmail")}>
                  <span className="mr-3">
                    <PlusCircle />
                  </span>
                  <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">{t("new")}</span>
                </NavLink>
              </li>
              <li className="nav-item mt-2">
                <small className="nav-subtitle pb-1" title={t("others")} style={{ fontSize: ".65625rem" }}>
                  {t("others")}
                </small>
                <hr className="nav-subtitle-replacer" />
              </li>
              <li className="navbar-vertical-aside-has-menu">
                <Link className="js-navbar-vertical-aside-menu-link nav-link" to="/integrations" rel="noopener noreferrer">
                  <span className="mr-3">
                    <Puzzle />
                  </span>
                  <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">{t("integrations")}</span>
                </Link>
              </li>
              <li className="navbar-vertical-aside-has-menu">
                <Link className="js-navbar-vertical-aside-menu-link nav-link" to="/store" rel="noopener noreferrer">
                  <span className="mr-3">
                    <Bag />
                  </span>
                  <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">{t("store")}</span>
                </Link>
              </li>
              <li className="navbar-vertical-aside-has-menu">
                <a
                  className="js-navbar-vertical-aside-menu-link nav-link"
                  href="https://academy.mailerfind.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="mr-3">
                    <Mortarboard />
                  </span>
                  <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                    {t("academy")} <BoxArrowUpRight size={10} className="ml-1 mb-1" />
                  </span>
                </a>
              </li>
              <li className="navbar-vertical-aside-has-menu">
                <a
                  className="js-navbar-vertical-aside-menu-link nav-link"
                  href="https://affiliates.mailerfind.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="mr-3">
                    <CashCoin />
                  </span>
                  <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">
                    {t("referEarn")} <BoxArrowUpRight size={10} className="ml-1 mb-1" />
                  </span>
                </a>
              </li>
            </ul>
          </div>

          {/* End Content */}
          {/* Footer */}
          <div className="navbar-vertical-footer">
            <ul className="navbar-vertical-footer-list">
              <li className="navbar-vertical-footer-list-item">
                {/* Unfold */}
                <div className="hs-unfold">
                  <Link className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle" to="/settings">
                    <FontAwesomeIcon icon={faCog} />
                  </Link>
                </div>
                {/* End Unfold */}
              </li>
              <li className="navbar-vertical-footer-list-item"></li>
            </ul>
          </div>
          {/* End Footer */}
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
