import { BACKEND_URL } from "../../utils/constants";
import makeRequest from "../client";

export const redeemOffer = async (jwt: any): Promise<any> => {
  return await makeRequest({
    url: `${BACKEND_URL}/v1/stripe/redeem-offer`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  });
};

export const getMySubscription = async (jwt: any): Promise<any> => {
  const sub = await makeRequest({
    url: `${BACKEND_URL}/v1/stripe/get-my-subscription`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  });
  return sub;
};

export const getAllInvoices = async (startAfter: string = "", limit: number = 10, token: string): Promise<any> => {
  return await makeRequest({
    url: `${BACKEND_URL}/v1/stripe/list-all-invoices?startAfter=${startAfter}&limit=${limit}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMyCoupon = async (jwt: any): Promise<any> => {
  return await makeRequest({
    url: `${BACKEND_URL}/v1/stripe/get-my-coupon`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  });
};

export const getCustomerPortalURL = async (returnUrl: string, jwt: string) => {
  const url = `${BACKEND_URL}/v1/stripe/create-customer-portal-session`;
  const method = "POST";
  const body = {
    returnUrl,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwt}`,
  };
  try {
    const response = await makeRequest({ url, method, body, headers });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getCustomerPortalURLWithFlow = async (
  returnUrl: string,
  jwt: string,
  flowType = "subscription_update",
  price = null,
  coupon: string | null
) => {
  const url = `${BACKEND_URL}/v1/stripe/create-customer-portal-session`;
  const method = "POST";
  const body = {
    returnUrl,
    flowType,
    price,
    coupon,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwt}`,
  };
  try {
    const response = await makeRequest({ url, method, body, headers });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getCheckoutSessionURL = async (priceId: string, coupon: string, trial: boolean, returnUrl: string, jwt: string) => {
  const url = `${BACKEND_URL}/v1/stripe/create-checkout-session`;
  const method = "POST";
  const body = {
    returnUrl,
    priceId,
    coupon,
    trial,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwt}`,
  };
  return await makeRequest({ url, method, body, headers });
};

export const buyCredits = async (quantity: number, jwt: string) => {
  const url = `${BACKEND_URL}/v1/stripe/buy-credits`;
  const method = "POST";
  const body = {
    quantity,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwt}`,
  };
  return await makeRequest({ url, method, body, headers });
};

export const buyProduct = async (id: string, jwt: string) => {
  const url = `${BACKEND_URL}/v1/stripe/buy-product`;
  const method = "POST";
  const body = {
    id,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwt}`,
  };
  return await makeRequest({ url, method, body, headers });
};

export const buySpeedAddon = async (speed: string, jwt: string) => {
  const url = `${BACKEND_URL}/v1/stripe/buy-speed-addon`;
  const method = "POST";
  const body = {
    speed,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwt}`,
  };
  return await makeRequest({ url, method, body, headers });
};

export const previewSubscriptionItem = async (speed: string, jwt: string) => {
  const url = `${BACKEND_URL}/v1/stripe/preview-speed-addon`;
  const method = "POST";
  const body = {
    speed,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwt}`,
  };
  return await makeRequest({ url, method, body, headers });
};

export const loadFreePlan = async (jwt: string) => {
  const url = `${BACKEND_URL}/v1/stripe/load-free-plan`;
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${jwt}`,
  };
  return await makeRequest({ url, method, headers });
};
