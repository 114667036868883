import { useTranslation } from "react-i18next";
import { Link45deg } from "react-bootstrap-icons";
import ConnectModal from "./ConnectModal";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { createIntegration, getIntegrationByTypeAndProject, removeIntegration } from "../../lib/firebase/integrations";
import { UserContext } from "../../contexts/UserContext";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import { ProjectContext } from "../../contexts/ProjectContext";

function Integration({ integration }: any) {
  const { user } = useContext(UserContext) as any;
  const { project } = useContext(ProjectContext) as any;
  const { t } = useTranslation("Integrations");
  const [show, setShow] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [myIntegration, setIntegration] = useState(null) as any;

  const handleConnectApp = (id: string) => {
    setShow(true);
  };

  const fetchIntegration = async () => {
    const myIntegration = await getIntegrationByTypeAndProject(user.firebaseUser.uid, integration.slug, project?.id || null);
    setIntegration(myIntegration);
  };

  useEffect(() => {
    fetchIntegration();
  }, [loading, project]);

  const handleApiKeySubmit = async (apiKey: string) => {
    try {
      setLoading(true);
      await createIntegration(user.firebaseUser.uid, integration.slug, project?.id || null, apiKey);
      toast.success(t("linkSuccessMessage"));
      setShow(false);
    } catch (err: any) {
      toast.error(err.message);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDisconnect = async (id: string) => {
    try {
      setLoading(true);
      await removeIntegration(user.firebaseUser.uid, myIntegration.id);
      toast.success(t("unlinkSuccessMessage"));
      setShowConfirmDelete(false);
    } catch (err: any) {
      toast.error(err.message);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="card card-bordered card-transition shadow-none h-100">
        <div className="card-body">
          <div className="d-flex">
            <div className="flex-shrink-0 my-auto">
              <img className="avatar avatar-sm mr-3 rounded" src={integration.image} alt="Logo" />
            </div>
            <div className="flex-grow-1 ms-3">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 mr-2">{t(integration.nameKey)}</h5>
                <img
                  className="avatar avatar-xss ms-1"
                  src="/assets/svg/illustrations/top-vendor.svg"
                  alt="Top vendor"
                  title="Top Vendor"
                />
              </div>
              <p className="card-text text-body small">{t("productivity")}</p>
            </div>
            <div>
              {myIntegration ? (
                <button className="btn btn-sm btn-outline-light border text-dark" onClick={() => setShowConfirmDelete(true)}>
                  <Link45deg /> {t("unlink")}
                </button>
              ) : (
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => handleConnectApp(integration.slug)}
                  disabled={loading || integration.commingSoon}
                >
                  <Link45deg /> {integration.commingSoon ? t("commingSoon") : t("connectApp")}
                </button>
              )}
            </div>
          </div>
          <p className="mt-3 card-text text-body small">{t(integration.descriptionKey)}</p>
        </div>
      </div>
      <ConnectModal show={show} setShow={setShow} handleApiKeySubmit={handleApiKeySubmit} loading={loading} integration={integration} />
      <DeleteConfirmationModal
        show={showConfirmDelete}
        close={() => setShowConfirmDelete(false)}
        onConfirm={() => handleDisconnect(integration.slug)}
        text={t("unlinkConfirmationMessage")}
      />
    </>
  );
}

export default Integration;
