import { Route, Routes } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import { BrowserRouter } from "react-router-dom";

// Pages
import Analysis from "./pages/Analysis";
import Senders from "./pages/Senders";

import AnalysisManager from "./pages/AnalysisManager";

import Emails from "./pages/Emails";
import NewEmail from "./pages/NewEmail";
import SendEmail from "./pages/SendEmail";
import Sender from "./pages/Sender";
import Settings from "./pages/Settings";
import ViewEmail from "./pages/ViewEmail";
import MyAccount from "./pages/MyAccount";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ProtectedRoute from "./components/ProtectedRoute";
import ForgotPassword from "./pages/ForgotPassword";
import DisclaimerV2 from "./pages/Disclaimer/v2";
import Dashboard from "./pages/Dashboard";
import Upgrade from "./pages/Upgrade";
import RefillCredits from "./pages/RefillCredits";
import EmailVerify from "./pages/EmailVerify";
import Store from "./pages/Store";
import Product from "./pages/Product";
import Prospects from "./pages/Prospects";
import Prospect from "./pages/Prospect";

import Wizard from "./pages/Wizard";
import { WizardProvider } from "./contexts/WizardContext";
import Download from "./pages/Download";
import Invitations from "./pages/Invitations";
import Demo from "./pages/Product/SpeedBoost/Demo";
import EmailSettings from "./pages/EmailSettings";
import Exports from "./pages/Exports";
import Integrations from "./pages/Integrations";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {/* AUTH */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/disclaimer" element={<DisclaimerV2 />} />

        <Route path="/verify" element={<EmailVerify />} />

        {/* HOME */}
        <Route
          index
          element={
            <ProtectedRoute>
              <MainLayout>
                <Dashboard />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* ANALYSIS */}
        <Route
          path="/analysis-manager"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AnalysisManager />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/analysis/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Analysis />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* EXPORTS */}
        <Route
          path="/exports"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Exports />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* EXPORTS */}
        <Route
          path="/exports/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Exports />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* EXPORTS */}
        <Route
          path="/exports/:id/download"
          element={
            <ProtectedRoute>
              <Download />
            </ProtectedRoute>
          }
        />

        {/* PROSPECTS */}
        <Route
          path="/prospects"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Prospects />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/prospects/:username"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Prospect />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* SENDERS */}
        <Route
          path="/senders"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Senders />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/senders/new/:provider"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Sender />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/senders/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Sender />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* EMAILS */}
        <Route
          path="/emails"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Emails />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/emails/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ViewEmail />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/emails/new"
          element={
            <ProtectedRoute>
              <MainLayout>
                <NewEmail />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/emails/edit/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <NewEmail />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/emails/send/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <SendEmail />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/emails/settings"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EmailSettings />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* STORE  */}
        <Route
          path="/store"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Store />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/product/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Product />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/product/:id/demo"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Demo />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* INTEGRATIONS */}
        <Route
          path="/integrations"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Integrations />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* UPGRADE AND REFILL */}
        <Route
          path="/upgrade"
          element={
            <ProtectedRoute>
              <Upgrade />
            </ProtectedRoute>
          }
        />
        <Route
          path="/refill-credits"
          element={
            <ProtectedRoute>
              <MainLayout>
                <RefillCredits />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* SETTINGS */}
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Settings />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* MY ACCOUNT */}
        <Route
          path="/my-account"
          element={
            <ProtectedRoute>
              <MainLayout>
                <MyAccount />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* INVITATIONS */}
        <Route
          path="/invitations"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Invitations />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* WIZARD */}
        <Route
          path="/onboarding/step/:id"
          element={
            <ProtectedRoute>
              <WizardProvider>
                <Wizard />
              </WizardProvider>
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
