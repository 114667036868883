import React, { useState, useEffect, useContext } from "react";
import { formatFollowers } from "../../utils/numberFormatter";
import ExportLoadingPopUp from "../../components/ExportLoadingPopUp";
import { Download, Search, Stars } from "react-bootstrap-icons";
import { auth } from "../../lib/firebase/firebase";
import { getAllProspectsInAnalysisWithFilter as getLocalProspects } from "../../lib/daos/prospectsDAO";
import { UserContext } from "../../contexts/UserContext";
import { getAllProspectsInAnalysisWithFilter as getCloudProspects } from "../../lib/firebase/prospects";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { exportAnalysis } from "../../api/mailerfind/exports";
import { exportAnalysisToCsv } from "../../utils/csvExporter";

function DataTable(props: any) {
  const [users, setUsers] = useState(props.users);
  const [selectedOption, setSelectedOption] = useState("any");
  const { user } = useContext(UserContext) as any;
  const { t } = useTranslation("DataTable");

  const [showExportPopUp, setShowExportPopUp] = useState(false);
  const [exportFinished, setExportFinished] = useState(false);

  const handleCloseExportPopUp = () => setShowExportPopUp(false);
  const handleShowExportPopUp = () => setShowExportPopUp(true);

  const MAX_ROWS = 100;

  const handleExport = async (format: string) => {
    handleShowExportPopUp();
    setExportFinished(false);

    const startExport = async (filter: string | null, format: string) => {
      try {
        if (props?.analysis?.isCloud) {
          const jwt = await auth.currentUser!.getIdToken();
          const response = await exportAnalysis(props.analysis.id, filter, format, jwt);
          if (response.queued) {
            setExportFinished(true);
            return;
          }
          window.open(response.url, "_blank");
          if (!response.queued) handleCloseExportPopUp();
          return response.queued;
        } else if (format === "csv") {
          await exportAnalysisToCsv(props.analysisId, props?.exportName || "users", filter || "all");
        } else {
          toast.error("Excel export is not supported for this analysis.");
        }
      } catch (err: any) {
        toast.error(err.message || "An error occurred while exporting the data.");
        handleCloseExportPopUp();
      }
    };

    let filter = null;
    switch (selectedOption) {
      case "users_with_email":
        filter = "email";
        break;
      case "users_with_first_name_and_email":
        filter = "email";
        break;
      case "users_with_phone":
        filter = "phone_number";
        break;
      case "users_with_website":
        filter = "website";
        break;
      default:
        filter = null;
    }

    await startExport(filter, format);
  };

  const handleSelectChange = (e: any) => {
    setSelectedOption(e.target.value);
  };

  const getProspects = async (analysis: any, filter: string) => {
    if (analysis?.isCloud) {
      return await getCloudProspects(user?.firebaseUser?.uid, analysis.id, filter);
    } else {
      return await getLocalProspects(Number(analysis.id), filter, 0, 100);
    }
  };

  useEffect(() => {
    let filteredUsers = props.users;
    switch (selectedOption) {
      case "users_with_email":
        getProspects(props.analysis, "email").then((prospects: any) => {
          setUsers(prospects);
        });
        break;
      case "users_with_first_name_and_email":
        getProspects(props.analysis, "name_and_email").then((prospects: any) => {
          setUsers(prospects);
        });
        break;
      case "users_with_phone":
        getProspects(props.analysis, "phone_number").then((prospects: any) => {
          setUsers(prospects);
        });
        break;
      case "users_with_website":
        getProspects(props.analysis, "website").then((prospects: any) => {
          setUsers(prospects);
        });
        break;
      default:
        setUsers(filteredUsers);
        break;
    }
  }, [props.users, selectedOption]);

  return (
    <>
      <div className="card" style={{ height: 300, overflowY: "auto" }}>
        <div className="card-header">
          <div className="row justify-content-between align-items-center flex-grow-1">
            <div className="col-12 col-md">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="card-header-title">{t("dataPreview")}</h3>
              </div>
            </div>
          </div>

          <div className="col-md-auto">
            <form>
              {/* Search */}
              <div className="input-group input-group-merge input-group-flush">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <Search />
                  </div>
                </div>
                <input
                  id="datatableSearch"
                  type="search"
                  className="form-control"
                  placeholder={t("searchUsers")}
                  aria-label={t("searchUsers")}
                  onChange={props.handleSearchChange}
                />
              </div>
              {/* End Search */}
            </form>
          </div>

          <div className="col-md-auto">
            <div className="form-group mb-0">
              <select
                id="exampleFormControlSelect1"
                className="form-control btn-white text-muted dropdown-toggle"
                onChange={handleSelectChange}
                value={selectedOption}
                disabled={props.users.length === 0}
              >
                <option value="any">{t("showAllUsers")}</option>
                <option value="users_with_email">{t("usersWithEmail")}</option>
                <option value="users_with_first_name_and_email">{t("usersWithEmailAndFirstName")}</option>
                <option value="users_with_phone">{t("usersWithPhone")}</option>
                <option value="users_with_website">{t("usersWithWebsite")}</option>
              </select>
            </div>
          </div>

          <div className="col-md-auto">
            <div className="btn-group">
              <button
                className="btn btn-white dropdown-toggle"
                type="button"
                id="dropdownMenuButtonWhite"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={props.users.length === 0}
              >
                <span className="mr-2">
                  <Download />
                </span>
                <span className="mr-1">{t("export")}</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <span className="dropdown-header px-3" style={{ fontSize: ".65625rem" }}>
                  {t("downloadOptions")}
                </span>
                <div className="dropdown-item px-3" onClick={() => handleExport("csv")} style={{ cursor: "pointer" }}>
                  <img
                    className="avatar avatar-xss avatar-4by3 mr-2"
                    src="/assets/svg/components/placeholder-csv-format.svg"
                    alt="Download CSV"
                  />
                  <span className="align-middle">.CSV</span>
                </div>

                <div className="dropdown-item px-3" onClick={() => handleExport("excel")} style={{ cursor: "pointer" }}>
                  <img className="avatar avatar-xss avatar-4by3 mr-2" src="/assets/svg/brands/excel.svg" alt="Download Excel" />
                  <span className="align-middle">Excel</span>
                </div>
                <div className="dropdown-divider"></div>
                <span className="dropdown-header px-3" style={{ fontSize: ".65625rem" }}>
                  {t("integrations")}
                </span>
                <div className="dropdown-item px-3" onClick={() => handleExport("smartlead")} style={{ cursor: "pointer" }}>
                  <img className="avatar avatar-xss mr-2" src="/assets/img/smartlead.webp" alt="Download PDF" />
                  <span className="align-middle">Smartlead (beta)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Table */}
        <div className="table-responsive">
          <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle">
            <thead className="thead-light" style={{ position: "sticky", top: 0, zIndex: 90 }}>
              <tr>
                <th>{t("nameAndUsername")}</th>
                <th>
                  {t("firstName")}{" "}
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => <Tooltip {...props}>{t("tooltipFirstName")}</Tooltip>}
                    placement="bottom"
                  >
                    <Stars size="14" className="mb-1" />
                  </OverlayTrigger>
                </th>
                <th>{t("email")}</th>
                <th>{t("website")}</th>
                <th>{t("phone")}</th>
                <th>{t("following")}</th>
                <th>{t("followers")}</th>
              </tr>
            </thead>
            <tbody>
              {users.slice(0, MAX_ROWS).map((user: any, index: any) => (
                <Row data={user} key={index} />
              ))}
            </tbody>
          </table>
        </div>
        {/* End Table */}
      </div>
      <ExportLoadingPopUp show={showExportPopUp} finished={exportFinished} close={handleCloseExportPopUp} />
    </>
  );
}

function Row(props: any) {
  return (
    <tr>
      <td>
        <a className="media align-items-center" href={`https://instagram.com/${props.data.username}`} target="_blank">
          {props.data.profile_pic_urll ? (
            <img
              src={
                "https://corsproxy.io/?https%3A%2F%2Fscontent-waw2-2.cdninstagram.com%2Fv%2Ft51.2885-19%2F342529940_252939000434118_4733278091810776232_n.jpg%3Fstp%3Ddst-jpg_e0_s150x150%26_nc_ht%3Dscontent-waw2-2.cdninstagram.com%26_nc_cat%3D106%26_nc_ohc%3DHR1dGfzmH-AQ7kNvgFensfq%26_nc_gid%3D675bcd6ff74b4cd88f21f3bebcff8e91%26edm%3DAEF8tYYBAAAA%26ccb%3D7-5%26oh%3D00_AYA__zKYpm_gKiqsPiYM9jLRqoAU3QbFA6oKpYg9dk7bBg%26oe%3D66ED04F3%26_nc_sid%3D1e20d2"
              }
              alt="profile"
              className="avatar avatar-sm avatar-circle mr-3"
            />
          ) : (
            <div className="avatar avatar-soft-dark avatar-circle mr-3">
              <span className="avatar-initials">
                {
                  // the first letter of the username in upper case
                  props?.data?.username?.charAt(0).toUpperCase()
                }
              </span>
            </div>
          )}
          <div className="media-body">
            <span className="d-block h5 text-hover-primary mb-0">
              {props.data?.full_name?.slice(0, 25) + (props.data?.full_name?.length > 25 ? "..." : "")}
            </span>
            <span className="d-block font-size-sm text-body">{props.data.username}</span>
          </div>
        </a>
      </td>
      <td>{props.data.first_name || "-"}</td>
      <td>{props.data.email ? props.data.email : "-"}</td>
      <td>
        <a href={props.data.website} target="_blank" rel="noopener">
          {props.data.website
            ? // show the website but crop it to the first 20 chars. if it's longer than 20 chars, add "..."
              props.data.website.slice(0, 25) + (props.data.website.length > 25 ? "..." : "")
            : "-"}
        </a>
      </td>
      <td>
        <PhoneNumberCell data={props.data} />
      </td>

      <td className="">{formatFollowers(props.data.following, 1)}</td>
      <td className="">{formatFollowers(props.data.followers, 1)}</td>
    </tr>
  );
}

function PhoneNumberCell(props: any) {
  // Intenta parsear el número de teléfono si está disponible
  const parsedPhoneNumber = props.data.phone_number
    ? parsePhoneNumberFromString(`+${props.data.phone_extension}${props.data.phone_number}`)
    : null;

  // Determina qué mostrar en la celda
  const displayValue = props.data.phone_number
    ? parsedPhoneNumber && parsedPhoneNumber.isValid()
      ? parsedPhoneNumber.formatInternational()
      : props.data.phone_number
    : "-";

  return <>{displayValue}</>;
}

export default DataTable;
