import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Book, BoxArrowUpRight } from "react-bootstrap-icons";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

function ConnectModal({ show, setShow, loading, integration, handleApiKeySubmit }: any) {
  const [apiKey, setApiKey] = useState("");
  const { t } = useTranslation("Integrations");

  const handleChange = (e: any) => {
    setApiKey(e.target.value);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    if (!apiKey || apiKey.length <= 5) {
      toast.error(t("invalidApiKeyError"));
      return;
    }
    handleApiKeySubmit(apiKey);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{t("connectModalTitle")}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSave}>
        <Modal.Body>
          <div className="form-group mb-0">
            <label htmlFor="apiKey">{t("apiKey")}</label>
            <input
              type="text"
              className="form-control"
              id="apiKey"
              value={apiKey}
              onChange={handleChange}
              placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
              autoComplete="off"
              inputMode="text"
              style={{ fontFamily: "Monospace" }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {integration.helpCenterLink && (
            <a className="text-left mr-auto mb-0 pb-0" href={integration.helpCenterLink} target="_blank" rel="noreferrer">
              <Book /> <span className="align-middle">Obtener ayuda</span> <BoxArrowUpRight size={10} className="ml-0 mb-1" />
            </a>
          )}
          <button disabled={loading} type="button" className="btn btn-white" onClick={() => setShow(false)}>
            {t("cancel")}
          </button>
          <button disabled={loading} type="submit" className="btn btn-primary">
            {t("save")}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default ConnectModal;
