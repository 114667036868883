import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, limit, query, setDoc, where } from "firebase/firestore";
import { db } from "./firebase";

/**
 * This function is used to get all the integrations of a user
 * @param userId - The user id
 * @returns - An array with all the integrations
 */
const getIntegrations = async (userId: string) => {
  const integrationsRef = collection(db, "users", userId, "integrations");
  const querySnapshot = await getDocs(integrationsRef);
  const integrations: any[] = querySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
  return integrations;
};

/**
 * This function is used to get the integration by id
 * @param userId - The user id
 * @param integrationId - The integration id
 * @returns - The integration object or null if it doesn't exist
 */
const getIntegrationById = async (userId: string, integrationId: string) => {
  const integrationRef = doc(db, "users", userId, "integrations", integrationId); // Referencia al documento
  const docSnap = await getDoc(integrationRef); // Usar getDoc para obtener el documento

  if (docSnap.exists()) {
    return docSnap.data(); // Si el documento existe, devuelve los datos
  } else {
    return null; // Si no existe, devuelve null
  }
};

/**
 * This function is used to get the integration by type and project, the project id can be null, that means that is the default project.
 * @param userId - The user id
 * @param type - The type of the integration
 * @param projectId - The project id
 * @returns The integration object or null if it doesn't exist
 */
const getIntegrationByTypeAndProject = async (userId: string, type: string, projectId: string) => {
  const integrationsRef = collection(db, "users", userId, "integrations");
  const q = query(integrationsRef, where("type", "==", type), where("project", "==", projectId), limit(1));
  const querySnapshot = await getDocs(q);
  const integrations: any[] = querySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });

  return integrations[0] || null;
};

/**
 * This function is used to create an integration
 * @param userId - The user id
 * @param type - The type of the integration
 * @param projectId - The project id
 * @param apiKey - The api key
 * @returns - Nothing
 * @throws - If there is an error creating the integration
 **/
const createIntegration = async (userId: string, type: string, projectId: string | null, apiKey: string) => {
  const integrationsRef = collection(db, "users", userId, "integrations");
  const integration = {
    project: projectId,
    type: type,
    apiKey,
    active: true,
    connectedAt: new Date(),
  };
  await addDoc(integrationsRef, integration);
};

/**
 * - This function is used to remove an integration
 * @param userId - The user id
 * @param integrationId - The integration id
 */
const removeIntegration = async (userId: string, integrationId: string) => {
  const integrationRef = doc(db, "users", userId, "integrations", integrationId);
  await deleteDoc(integrationRef);
};

export { createIntegration, removeIntegration, getIntegrations, getIntegrationById, getIntegrationByTypeAndProject };
