import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase";
import { DiscountCampaign } from "../../types/DiscountTypes";

export const getActiveCampaigns = async (type: string) => {
  const discountCampaignsRef = collection(db, "discountCampaigns");

  const now = new Date();
  const q = query(discountCampaignsRef, where("endDate", ">=", now), where("appliesTo", "==", type));

  const querySnapshot = await getDocs(q);
  const campaigns: DiscountCampaign[] = querySnapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      name: data.name,
      startDate: data.startDate,
      endDate: data.endDate,
      discountPercentage: data.discountPercentage,
      eligiblePlans: data.eligiblePlans,
      appliesTo: data.appliesTo,
      banner: data.banner,
      color: data.color,
    } as DiscountCampaign;
  });

  return campaigns;
};

// Returns the best discount for the user based on their plan and the type of discount
// Returns the DiscountCampaign object
export const getBestDiscount = async (userPlan: string, type: string) => {
  const campaigns = await getActiveCampaigns(type);
  const userCampaigns = campaigns.filter((campaign) => campaign.eligiblePlans.includes(userPlan));
  const bestCampaign = userCampaigns.reduce(
    (prev, current) => (prev.discountPercentage > current.discountPercentage ? prev : current),
    userCampaigns[0]
  );
  return bestCampaign;
};
