import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import React from "react";
import Integration from "./Integration";

const integrations = [
  {
    id: 1,
    nameKey: "smartlead",
    slug: "smartlead",
    descriptionKey: "smartleadDescription",
    published: true,
    commingSoon: false,
    image: "/assets/img/smartlead.webp",
    helpCenterLink: "https://help.mailerfind.com/es/article/integracion-con-smartlead-aitwyz/",
    connectionMethod: "apiKey",
  },
  {
    id: 2,
    nameKey: "instantly",
    slug: "instantly",
    descriptionKey: "instantlyDescription",
    published: true,
    commingSoon: true,
    image: "/assets/img/instantly.jpeg",
    helpCenterLink: "https://help.mailerfind.com/es/article/integracion-con-instantly-1kly46n/",
    connectionMethod: "apiKey",
  },
  {
    id: 3,
    nameKey: "lemlist",
    slug: "lemlist",
    descriptionKey: "lemlistDescription",
    published: true,
    commingSoon: true,
    image: "/assets/img/lemlist.png",
    helpCenterLink: "https://help.mailerfind.com/es/article/integracion-con-smartlead-aitwyz/",
    connectionMethod: "apiKey",
  },
  {
    id: 4,
    nameKey: "millionverifier",
    slug: "millionverifier",
    descriptionKey: "millionverifierDescription",
    published: true,
    commingSoon: true,
    image: "/assets/img/millionverifier.png",
    helpCenterLink: "https://help.mailerfind.com/es/article/integracion-con-smartlead-aitwyz/",
    connectionMethod: "apiKey",
  },
  {
    id: 5,
    nameKey: "listarobinson",
    slug: "listarobinson",
    descriptionKey: "listarobinsonDescription",
    published: false,
    commingSoon: true,
    image: "/assets/img/robinson.png",
    helpCenterLink: "https://help.mailerfind.com/es/article/integracion-con-smartlead-aitwyz/",
    connectionMethod: "apiKey",
  },
];

function Integrations() {
  const { t } = useTranslation("Integrations");

  return (
    <div>
      <Header title={t("title")} subtitle={t("subtitle")} breadCrumbItems={[{ href: "/integrations", title: t("title") }]}></Header>
      <div className="row">
        {integrations.map((integration) => {
          return (
            <React.Fragment key={integration.id}>
              {integration.published && (
                <div className="col-lg-4 mb-4">
                  <Integration integration={integration} />
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default Integrations;
