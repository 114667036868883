import React, { useContext, useEffect, useState } from "react";
import { deleteProject, getProjects, updateProject } from "../lib/firebase/projects";
import { ProjectContext } from "../contexts/ProjectContext";
import { UserContext } from "../contexts/UserContext";
import toast from "react-hot-toast";
import { Button, Modal } from "react-bootstrap";
import { Check, FolderPlus, GearFill, PenFill, ThreeDotsVertical, TrashFill, X } from "react-bootstrap-icons";
import NewProjectModal from "./NewProjectModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useTranslation } from "react-i18next";

function ManageProjectsModal({ show, setShow }: any) {
  const { t } = useTranslation("Component.ManageProjectsModal");
  const { user } = useContext(UserContext) as any;
  const { project, setProject } = useContext(ProjectContext) as any;
  const [projects, setProjects] = useState([]) as any;
  const [projectToDelete, setProjectToDelete] = useState(null) as any;
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const [editMode, setEditMode] = useState({
    active: false,
    id: null,
    value: "",
  });

  const [showNewProjectModal, setShowNewProjectModal] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);

  const getProjectList = async () => {
    try {
      const projects = await getProjects(user.firebaseUser.uid);
      setProjects(projects);
    } catch (err: any) {
      toast.error(t("errorFetchingProjects"));
    }
  };

  const handleSelectProject = (project: any) => {
    setProject(project);
    handleClose();
  };

  const showDeleteModal = (project: any) => {
    setProjectToDelete(project);
    setShow(false);
    setShowDeleteProjectModal(true);
  };

  const handleDeleteProject = async (_project: any) => {
    let toastId = null;
    try {
      setLoading(true);
      toastId = toast.loading(t("deleting"));
      await deleteProject(user.firebaseUser.uid, _project.id);
      await getProjectList();
      if (project?.id === _project.id) setProject(null); // Set default project
      toast.success(t("projectDeleted", { projectName: _project.name }), {
        id: toastId,
      });
    } catch (err: any) {
      toast.error(err.message || t("errorDeletingProject"), {
        id: toastId,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjectList();
  }, [project, editMode]);

  const handleCreateNewProject = () => {
    handleClose();
    setShowNewProjectModal(true);
  };

  const activateEditMode = (project: any) => {
    setEditMode((prevState: any) => ({
      ...prevState,
      id: project?.id,
      active: true,
      value: project.name,
    }));
  };

  const cancelEditMode = () => {
    setEditMode((prevState: any) => ({
      ...prevState,
      id: null,
      active: false,
      value: "",
    }));
  };

  const handleNameChange = (e: any) => {
    setEditMode((prevState: any) => ({
      ...prevState,
      value: e.target.value,
    }));
  };

  const saveEditMode = async () => {
    try {
      if (!editMode?.id) return;
      await updateProject(user.firebaseUser.uid, editMode?.id, { name: editMode.value });
      setEditMode((prevState: any) => ({
        ...prevState,
        id: null,
        active: false,
        value: "",
      }));
      toast.success(t("changesSaved"));
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header>
          <Modal.Title>{t("projects")}</Modal.Title>
          <button type="button" className="btn btn-outline-primary" onClick={handleCreateNewProject} disabled={loading}>
            <FolderPlus className="mr-1" /> <span className="align-middle">{t("newProject")}</span>
          </button>
        </Modal.Header>
        <Modal.Body className="py-3">
          <div className="table-responsive">
            <table className="table table-lg table-thead-bordered table-nowrap table-align-middle">
              <thead className="thead-white font-weight-light" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                <tr>
                  <th>{t("name")}</th>
                  <th>ID</th>
                  <th>{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                <Row project={null} handleSelectProject={handleSelectProject} />
                {projects.map((project: any, index: any) => (
                  <Row
                    project={project}
                    key={index}
                    handleSelectProject={handleSelectProject}
                    showDeleteModal={showDeleteModal}
                    activateEditMode={activateEditMode}
                    saveEditMode={saveEditMode}
                    cancelEditMode={cancelEditMode}
                    editMode={editMode}
                    handleNameChange={handleNameChange}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div className="d-flex flex-column">
            <div className="progress mb-2" style={{ minWidth: "30px" }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${(projects.length / 3) * 100}%` }}
                aria-valuenow={25}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
            <div>{t("projectsCreated", { count: projects.length })}</div>
          </div>

          <Button variant="white" onClick={handleClose} disabled={loading}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
      <NewProjectModal show={showNewProjectModal} setShow={setShowNewProjectModal} />
      <DeleteConfirmationModal
        show={showDeleteProjectModal}
        close={() => setShowDeleteProjectModal(false)}
        data={projectToDelete}
        onConfirm={handleDeleteProject}
        typeToDelete={true}
        text={t("deleteProjectConfirmation")}
      />
    </>
  );
}

function Row(props: any) {
  const { t } = useTranslation("Component.ManageProjectsModal");
  return (
    <tr>
      <td className="py-1">
        {props.editMode?.active === true && props.editMode?.id === props.project.id ? (
          <div className="input-group">
            <input type="text" className="form-control" value={props.editMode.value} onChange={props.handleNameChange} />
          </div>
        ) : (
          <>
            <div className="avatar avatar-soft-info avatar-circle">
              <span className="avatar-initials">{props.project?.name[0] || "D"}</span>
            </div>

            <div onClick={() => props.handleSelectProject(props.project)} style={{ cursor: "pointer" }} className="d-inline-block ml-3">
              <h5 className="mb-0 project">{props.project?.name || t("defaultProject")}</h5>
            </div>
          </>
        )}
      </td>

      <td className="py-1">{props.project?.id || "default"}</td>
      <td className="text-right">
        <div className="dropdown d-none d-md-block">
          {props.editMode?.active === true && props.editMode?.id === props.project.id ? (
            <div>
              <button className="btn btn-white text-success btn-sm mr-2" onClick={props.saveEditMode}>
                <Check size={20} /> <span className="align-middle">{t("save")}</span>
              </button>
              <button className="btn btn-white text-danger btn-sm mr-2" onClick={props.cancelEditMode}>
                <X size={20} /> <span className="align-middle">{t("cancel")}</span>
              </button>
            </div>
          ) : (
            <>
              <button
                className="btn btn-outline-white border btn-sm dropdown-toggle"
                type="button"
                id="dropdownMenuButton3"
                data-toggle="dropdown"
                data-bs-display="static"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <ThreeDotsVertical />
              </button>

              <div className="dropdown-menu px-3" aria-labelledby="dropdownMenuButton3">
                {props.project && (
                  <span className="dropdown-item" onClick={() => props.activateEditMode(props.project)}>
                    <PenFill className="mr-1" /> {t("rename")}
                  </span>
                )}
                <span className="dropdown-item">
                  <GearFill className="mr-1" /> {t("configure")}
                </span>
                {props.project && (
                  <span className="dropdown-item" onClick={() => props.showDeleteModal(props.project)}>
                    <TrashFill className="mr-1" />
                    {t("delete")}
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      </td>
    </tr>
  );
}

export default ManageProjectsModal;
