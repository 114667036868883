import React, { useState, useEffect, useContext } from "react";
import Countdown from "react-countdown";
import { auth } from "../lib/firebase/firebase";
import { getMyCoupon } from "../api/mailerfind/stripe";
import toast from "react-hot-toast";
import PremiumModal from "./PremiumModal";
import { useTranslation } from "react-i18next";
import { isCyberWeek } from "../utils/helpers";
import { UserContext } from "../contexts/UserContext";

function formatNumber(number: number) {
  return String(number).padStart(2, "0");
}

function BlackFridayTimer() {
  const { t } = useTranslation("Component.BlackFrdayTimer");
  const { user } = useContext(UserContext) as any;

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <></>;
    } else {
      // Render a countdown
      return (
        <div style={{ marginTop: 55 }}>
          <div
            style={{ position: "fixed", top: 0, zIndex: 100, width: "100%" }}
            className="bg-primary text-white p-2 d-flex align-items-center justify-content-center"
          >
            <span className="font-weight-bolder countdown-column">
              <img src="/assets/img/reloj.png" alt="Reloj" className="img-fluid avatar avatar-sm mr-3" />
              ¡CYBERWEEK! HASTA 75% DE DESCUENTO
            </span>
            <span className="countdown-column">
              <span className="countdown-item">{formatNumber(days)}</span>
              <span className="countdown-item">{formatNumber(hours)}</span>
              <span className="countdown-item">{formatNumber(minutes)}</span>
              <span className="countdown-item">{formatNumber(seconds)}</span>
            </span>
            <span className="countdown-column">
              <a className="btn btn-light btn-xs text-dark font-weight-bolder" href="https://mailerfind.com/es/pricing">
                Mejorar Plan
              </a>
            </span>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {isCyberWeek() && user.firebaseUser.plan === "free" && (
        <>
          <Countdown date={new Date("2024-12-08T23:55:00")} zeroPadTime={2} renderer={renderer} />
        </>
      )}
    </>
  );
}

export default BlackFridayTimer;
