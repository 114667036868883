/**
 * Extract emails from a string
 * @param str - The string to extract emails from
 * @returns The emails found in the string
 */
function extractEmailFromString(str: string): string[] | null {
  return str.match(/[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+/gi);
}

/**
 * Check if an object is empty
 * @param obj - The object to check
 * @returns True if the object is empty
 */
function isEmpty(obj: any): boolean {
  return Object.keys(obj).length === 0;
}

/**
 * Capitalize the first letter of each word in a string
 * @param string - The string to capitalize
 * @returns The capitalized string
 */
const capitalizeString = (string: string): string => {
  return string
    .split(" ")
    .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

/**
 * Checks if a URL is a valid Instagram URL
 * @param url - The URL to check
 * @returns True if the URL is a valid Instagram URL
 */
function isValidInstagramURL(url: string): boolean {
  const regex = /https?:\/\/www\.instagram\.com\/(p|reel)\/[\w-]+\/?/;
  return regex.test(url);
}

/**
 * Check if an email is valid (only checks the format)
 * @param email - The email to check
 * @returns True if the email is valid
 */
function isEmail(email: string): boolean {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

/**
 * Convert bytes to MB
 * @param bytes  - The bytes to convert to MB
 * @returns The bytes in MB
 */
const bytesToMB = (bytes: any): string => {
  const mb = bytes / (1024 * 1024);
  return mb.toFixed(2); // Redondea a dos decimales
};

/**
 * Convert a user object to a prospect object
 * @param user - The user object
 * @param analysisId - The analysis ID
 * @returns The prospect object
 */
const convertProspect = (user: any, analysisId: Number): object => {
  const bioEmail = user.biography && extractEmailFromString(user.biography);
  const fullNameEmail = user.full_name && extractEmailFromString(user.full_name);
  const newUser = {
    pk: user.pk,
    username: user.username,
    biography: user.biography,
    full_name: user.full_name,
    first_name: user.first_name,
    email: user.public_email || bioEmail || fullNameEmail || null,
    followers: user.follower_count || user.followers,
    following: user.following_count || user.following,
    profile_pic_url: user.profile_pic_url,
    is_business: user.is_business_account === undefined ? user.is_business : user.is_business_account,
    website: user.external_url || null,
    phone_number: user.public_phone_number || null,
    phone_extension: user.public_phone_country_code || null,
    city: user.city_name || null,
    analysisId: analysisId,
  };
  return newUser;
};

/**
 * Check if the analysis is in the cloud and not a local one
 * @param analysisId - The analysis ID
 * @returns True if the analysis is in the cloud
 */
const analysisIsCloud = (analysisId: string | number): boolean => {
  analysisId = analysisId.toString();
  const isLocal = /^\d+$/.test(analysisId);
  return !isLocal;
};

/**
 * Sanitize a name by removing special characters
 * @param name - The name to sanitize
 * @returns The sanitized name
 */
const sanitizeName = (name: string): string => {
  return name.replace(/[^a-zA-ZñÑ\- áéíóúÁÉÍÓÚ]/g, "");
};

/**
 * Get the language of the browser
 * @returns The language of the browser
 */
function getBrowserLanguage(): string {
  // Obtener el idioma del navegador
  const lang = navigator.language || navigator.languages[0];

  // Convertir el idioma a minúsculas para asegurar uniformidad
  const lowerLang = lang.toLowerCase();

  // Verificar si el idioma es inglés o español
  if (lowerLang.startsWith("en")) {
    return "en";
  } else if (lowerLang.startsWith("es")) {
    return "es";
  } else {
    // Valor por defecto
    return "en";
  }
}

/**
 * Convert a string to camelCase
 * @param str - The string to convert
 * @returns The string in camelCase
 */
function toCamelCase(str: string): string {
  return str
    .toLowerCase() // Convertimos todo a minúsculas primero
    .replace(/[-_\s]+(.)?/g, function (match, char) {
      return char ? char.toUpperCase() : "";
    });
}

/**
 * Check if today is Black Friday
 * @returns {boolean} - True if today is Black Friday
 */
function isCyberWeek(): boolean {
  const now = new Date();
  const start = new Date("2024-12-02");
  const end = new Date("2024-12-08");
  return now >= start && now <= end;
}

export default extractEmailFromString;
export {
  isEmail,
  isEmpty,
  capitalizeString,
  isValidInstagramURL,
  bytesToMB,
  toCamelCase,
  convertProspect,
  analysisIsCloud,
  sanitizeName,
  getBrowserLanguage,
  isCyberWeek,
};
